import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../middlewares/products/product";
import LocalstorageService from "../../helpers/localstorage-services";
import { LOCALSTORAGE_CART_KEY, LOCALSTORAGE_CATEGORIES_KEY, LOCALSTORAGE_WISHLIST_KEY } from "../../config/constants";
import { addProductWishList, removeProductWishList } from "../../middlewares/wishlist/wishlist";
import { toast } from "react-toastify";
import { deleteProductWishListError, resetAddProductWishList, resetDeleteProductWishList, updateWishListCount } from "../../slices/wishlist.slice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { updateCartCount } from "../../slices/cart";
import PaginationHelper from "../../helpers/Pagination";

const ProductListData = ({ categoryLabel }) => {
  const dispatch = useDispatch();
  const storedWishlist = LocalstorageService.getInLocalStorage(LOCALSTORAGE_WISHLIST_KEY) || [];
  const storedCart = LocalstorageService.getInLocalStorage(LOCALSTORAGE_CART_KEY) || [];

  const {
    productListLoading,
    productsList,
    productListMessage,
    productListError,
  } = useSelector((state) => state.product);
  const {
    getWishList,
    getWishListError,
    addProductWishListError,
    addProductWishListLoading,
    addProductWishListSuccessMessage,
    deleteProductWishListSuccessMessage,
    deleteProductWishListError,
  } = useSelector((state) => state.wishlist);

  const navigate = useNavigate();
  const catList = LocalstorageService.getInLocalStorage(
    LOCALSTORAGE_CATEGORIES_KEY
  )
    ? LocalstorageService.getInLocalStorage(LOCALSTORAGE_CATEGORIES_KEY)
    : [];

  useEffect(() => {
    dispatch(updateCartCount(storedCart?.length))
  }, [dispatch])

  useEffect(() => {
    if (addProductWishListSuccessMessage.message) {
      const newProduct = productsList?.data?.find(o => o.id === addProductWishListSuccessMessage?.data?.product_id);
      const updatedWishlist = [...storedWishlist, { id: addProductWishListSuccessMessage?.data?.wishlist_id, product: newProduct }];
      LocalstorageService.setInLocalStorage(LOCALSTORAGE_WISHLIST_KEY, updatedWishlist);
      dispatch(updateWishListCount(updatedWishlist?.length));
    }
    if (deleteProductWishListSuccessMessage.message) {
      const updatedWishlist = storedWishlist.filter(item => item?.product?.id !== deleteProductWishListSuccessMessage?.data?.product_id);
      LocalstorageService.setInLocalStorage(LOCALSTORAGE_WISHLIST_KEY, updatedWishlist);
      dispatch(updateWishListCount(updatedWishlist?.length));
    }
  }, [addProductWishListSuccessMessage, deleteProductWishListSuccessMessage, storedWishlist, productsList]);

  useEffect(() => {
    if (addProductWishListError) {
      toast.error(addProductWishListError);
      dispatch(resetAddProductWishList());
    }
    if (deleteProductWishListError) {
      toast.error(deleteProductWishListError);
      dispatch(resetDeleteProductWishList());
    }
    if (addProductWishListSuccessMessage) {
      toast.success(addProductWishListSuccessMessage.message);
      dispatch(resetAddProductWishList());
    }
    if (deleteProductWishListSuccessMessage) {
      toast.success(deleteProductWishListSuccessMessage.message);
      dispatch(resetDeleteProductWishList());
    }
  }, [
    addProductWishListError,
    deleteProductWishListError,
    addProductWishListSuccessMessage,
    deleteProductWishListSuccessMessage
  ]);

  const handleWishlistToggle = (elem) => {
    const user = LocalstorageService.getLoggedInUserDetails();
    if (!user?.id) {
      toast.error("User not logged in");
      return;
    }

    const bodyFormData = new URLSearchParams();
    bodyFormData.append("user_id", user.id);
    bodyFormData.append("product_id", elem.id);

    const isProductInWishlist = storedWishlist.some(item => item.product.id === elem.id);

    if (isProductInWishlist) {
      dispatch(removeProductWishList(bodyFormData));
    } else {
      dispatch(addProductWishList(bodyFormData));
    }
  };
  useEffect(() => {
    if (categoryLabel) {
      const filter = {
        category_id: catList.find((o) => o?.slug === categoryLabel)?.id
      }
      const formData = new FormData();
      formData.append("current_page", 1);
      formData.append("filter", JSON.stringify(filter));
      dispatch(fetchProducts(formData));
    }
  }, [categoryLabel]);

  const handlePagination = (e) => {
    if (categoryLabel) {
      const formData = new FormData();
      formData.append(
        "category_id",
        catList.find((o) => o?.slug === categoryLabel)?.id
      );
      formData.append(
        "current_page",
        e);
      dispatch(fetchProducts(formData));
    }
  };


  return (
    <div className="lidting-padding proudct-list-data">
      <div className="row">
        {productListLoading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mt-2">
              <Skeleton className="listing-product-img" height={300} />
              <Skeleton count={4} />
            </div>
          ))
        ) : productsList?.length === 0 ? (
          <div className="col-12">
            <div className="product-not-found">
              <img className=""
                src="/assets/images/product-not.jpg"
                style={{ width: "300px", height: "300px" }}
              />
            </div>
            {/* <h6 className="text-center mb-3">{productListError}</h6> */}
            <h6 className="text-center mb-3">Product data not found</h6>
          </div>
        ) : (
          productsList?.data?.map((elem) =>
            <div key={elem.id} className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6" >
              <div className="listing-product-card">
                <div className="listing-product-inner-box">
                  <Swiper className="listing-product-slider">
                    {/* {elem.photos.map((image, i) => (
                                        ))} */}
                    <SwiperSlide key={"1"} className="listing-product-img">
                      <LazyLoadImage src={`${elem.photos}`} alt={`pro-1`}
                        onClick={() => window.open(`/${categoryLabel}/product-details/${elem.id}`, '_blank')}
                        style={{ cursor: 'pointer' }} />
                    </SwiperSlide>
                  </Swiper>
                  <div className="flex wishlist-content justify-content-between">
                    <div className="Rating flex">
                      <h6 className="flex gap-1">{elem.rating} <span className="mx-1"><i className="star-icon fa-solid fa-star" /></span></h6>
                      {/* <h6><span className="border-left1" /> {elem.reviews}</h6> */}
                    </div>
                    <div className="wishlist-icon" style={{ cursor: 'pointer' }} onClick={() => handleWishlistToggle(elem)}>
                      <i className={`fa-heart ${storedWishlist?.findIndex(item => item?.product.id === elem?.id) === -1 ? 'fa-regular text-black' : 'fa-solid text-danger'}`} />
                    </div>
                  </div>
                </div>
                <div className="listing-product-details">
                  <p>Made in India</p>
                  <h5>{elem.name}</h5>
                  {/* <p className="pro-des" dangerouslySetInnerHTML={{__html:elem.description}}/> */}
                  {/* {elem.description}</p> */}
                  <h4>
                    <del style={{ fontSize: 12 }}>₹{elem.tag_price}</del>
                    <span style={{ fontSize: 14, padding: '2px 10px', }}>₹{elem.indiazona_price}</span>
                    <span className="off-span" style={{ borderLeft: '1px solid #000', padding: '2px 10px', fontSize: 14 }}>({elem.discount}%)</span>
                  </h4>
                  {/* <h6 className="daily-saver-text">Daily Saver</h6> */}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="row">`
        {/* {console.log(productsList)} */}
        {productsList?.metaData?.totalPages > 1 &&
          <PaginationHelper
            itemClick={(o) => handlePagination(o)}
            activeItem={(o) =>
              parseInt(productsList?.metaData?.current_page) === o
            }
            prev={() => {
              handlePagination(
                parseInt(productsList?.metaData?.current_page) - 1
              );
            }}
            next={() =>
              handlePagination(
                parseInt(productsList?.metaData?.current_page) + 1
              )
            }
            items={productsList?.metaData?.totalPages}
            prevDisabled={
              parseInt(productsList?.metaData?.current_page) === 1
            }
            nextDisabled={
              parseInt(productsList?.metaData?.current_page) ===
              productsList?.metaData?.totalPages
            }
            first_page={() =>
              handlePagination(
                parseInt(productsList?.metaData?.current_page) === 1
              )
            }
            last_page={() =>
              handlePagination(productsList?.metaData?.totalPages)
            }
          />
        }
      </div>
    </div>
  );
};

export default ProductListData;
