import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, EffectCreative } from 'swiper/modules';
import SizeChart from '../Model/SizeChart';
import CompareModel from '../Model/CompareModel';
import { OverlayTrigger } from 'react-bootstrap';
import ReactToolTip from '../Common/ReactToolTip';
import ProductAccordion from './ProductDetailsAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProductDetails } from '../../middlewares/products/product';
import { useFormik } from 'formik';
import { Check } from 'lucide-react';
import LocalstorageService from '../../helpers/localstorage-services';
import { addProductWishList, removeProductWishList } from '../../middlewares/wishlist/wishlist';
import { addProductWishListError, resetAddProductWishList, resetDeleteProductWishList, updateWishListCount } from '../../slices/wishlist.slice';
import RatingGraphSection from './RatingGraphSection';
import { addToCart } from '../../middlewares/cart/cart';
import SideCartMenu from './SideCartMenu';
import { resetAddedCartList } from '../../slices/cart';
import { toast } from 'react-toastify';
import { LOCALSTORAGE_WISHLIST_KEY } from '../../config/constants';
import * as Yup from 'yup';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/effect-creative';
import SellerDetails from './ProductDetails/SellerDetails';

const ProductDetails = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showSideCart, setShowSideCart] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [showAllColors, setShowAllColors] = useState(false);
  const handleHideSizeChart = () => setShowSizeChart(false);
  const handleCloseSideCart = () => setShowSideCart(false)
  const handleShowCompare = () => setShowCompare(true);
  const handleHideCompare = () => setShowCompare(false);

  const dispatch = useDispatch();
  const { category, id } = useParams();
  const navigate = useNavigate();
  const { productDetails, productDetailsLoading, } = useSelector(state => state.product);
  const { cartAddError, cartAddLoading, cartAddMessage } = useSelector(state => state.cart);
  const {
    getWishList,
    getWishListError,
    addProductWishListError,
    addProductWishListLoading,
    addProductWishListSuccessMessage,
    deleteProductWishListSuccessMessage,
    deleteProductWishListError,
  } = useSelector((state) => state.wishlist);

  const [isInWishlist, setIsInWishlist] = useState(false);
  const user = LocalstorageService.getLoggedInUserDetails();
  const colorsToShow = showAllColors ? productDetails?.product_colors : productDetails?.product_colors?.slice(0, 5);
  const storedWishlist = LocalstorageService.getInLocalStorage(LOCALSTORAGE_WISHLIST_KEY) || [];
  const handleIncrement = () => {
    if (formik.values.quantity < 10) {
      formik.setFieldValue('quantity', formik.values.quantity + 1);
    }
  };

  const handleDecrement = () => {
    formik.setFieldValue('quantity', Math.max(formik.values.quantity - 1, 1));
  };

  const handleShowMore = () => {
    setShowAllColors(true);
  };

  useEffect(() => {
    if (cartAddMessage) {
      toast.success(cartAddMessage);
      dispatch(resetAddedCartList());
      setShowSideCart(true);
    }
  }, [cartAddLoading, cartAddMessage]);

  useEffect(() => {
    if (id) {
      const formData = new FormData();
      formData.append('product_id', id);
      dispatch(fetchProductDetails(formData));
    }
  }, [id])

  const toggleWishlist = () => {
    if (!user?.id) {
      toast.error("User not logged in");
      navigate("/login");
      return;
    }
    const bodyFormData = new URLSearchParams();
    bodyFormData.append("user_id", user?.id);
    bodyFormData.append("product_id", id);
    if (isInWishlist) {
      dispatch(removeProductWishList(bodyFormData));
    } else {
      dispatch(addProductWishList(bodyFormData));
    }
    setIsInWishlist(!isInWishlist);
  };

  const handleAddtoCart = () => {
    if (!user?.id) {
      toast.error("User not logged in");
      navigate("/login");
      return;
    }
    if (!formik.values.selectedColor) {
      formik.setFieldError('selectedColor', 'Please select a color');
      return;
    }
    const selectedColorData = productDetails?.attributes?.colors?.find((o) => o.code === formik.values.selectedColor);
    if (selectedColorData?.sizes?.length > 0) {
      if (!formik.values.size) {
        formik.setFieldError('size', 'Please select a size');
        return;
      }
    }
    const variant = [{
      color: formik.values.selectedColor,
      size: formik.values.size
    }].filter(Boolean);
    const bodyFormData = new FormData();
    bodyFormData.append('user_id', user?.id);
    bodyFormData.append('product_id', productDetails?.id);
    bodyFormData.append('quantity', formik.values.quantity);
    bodyFormData.append('variant', JSON.stringify(variant));
    dispatch(addToCart(bodyFormData));
  }
  const handleColorClick = (colorCode) => {
    const newColor = selectedColor === colorCode ? '' : colorCode;
    setSelectedColor(newColor);
    formik.setFieldValue('selectedColor', newColor);
  };
  const formik = useFormik({
    initialValues: {
      selectedColor: '',
      size: '',
      quantity: 1,
    },
    validationSchema: Yup.object({
      selectedColor: Yup.string().required('Please select a color'),
      size: Yup.string().required('Please select a size'),

    }),
    onSubmit: handleAddtoCart,
  });

  useEffect(() => {
    if (productDetails?.data) {
      setIsInWishlist(storedWishlist.some(item => item.product?.id === productDetails?.id));
    }
  }, [productDetails, storedWishlist]);

  useEffect(() => {
    if (addProductWishListError) {
      toast.error(addProductWishListError);
      dispatch(resetAddProductWishList());
    }
    if (deleteProductWishListError) {
      toast.error(deleteProductWishListError);
      dispatch(resetDeleteProductWishList());
    }
    if (addProductWishListSuccessMessage.message) {
      toast.success(addProductWishListSuccessMessage.message);
      const updatedWishlist = [...storedWishlist, { id: addProductWishListSuccessMessage.data?.wishlist_id, product: productDetails }];
      LocalstorageService.setInLocalStorage(LOCALSTORAGE_WISHLIST_KEY, updatedWishlist);
      dispatch(updateWishListCount(updatedWishlist?.length));
      setIsInWishlist(true);
      dispatch(resetAddProductWishList());
    }
    if (deleteProductWishListSuccessMessage.message) {
      toast.success(deleteProductWishListSuccessMessage.message);
      const updatedWishlist = storedWishlist.filter(item => item.product?.id !== productDetails?.id);
      LocalstorageService.setInLocalStorage(LOCALSTORAGE_WISHLIST_KEY, updatedWishlist);
      dispatch(updateWishListCount(updatedWishlist?.length));
      setIsInWishlist(false);
      dispatch(resetDeleteProductWishList());
    }
  }, [addProductWishListError, addProductWishListSuccessMessage, deleteProductWishListError, deleteProductWishListSuccessMessage, storedWishlist, productDetails]);

  console.log(formik.values);
  return (
    <section className="details-wrapper-section">
      <div className="container-fluid">
        <div className="details-inner-wrapper">
          <div id="single-product">
            <div className="row product-detail-summary justify-content-between">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="easyzoom-product-gallery">
                  <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <Swiper 
                        style={{
                          '--swiper-navigation-color': '#fff',
                          '--swiper-pagination-color': '#fff',
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs, EffectCreative]}
                        effect={'creative'}
                        creativeEffect={{
                          prev: {
                            shadow: true,
                            translate: [0, 0, -400],
                          },
                          next: {
                            translate: ['100%', 0, 0],
                          },
                        }}
                        className="mySwiper2 product-slider-lg"
                      >
                        {productDetails?.photos?.map((elem, i) =>
                          <SwiperSlide key={`main-slide-${i}`}>
                            <img src={elem?.path} loading="lazy" alt={`Product-${i}`} />
                          </SwiperSlide>
                        )}
                      </Swiper>
                    </div>
                    <div className="col-12 mt-2">
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={6}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper product-slider-sm"
                      >
                        {productDetails?.photos?.map((elem, i) =>
                          <SwiperSlide key={`thumb-slide-${i}`}>
                            <img src={elem.path} loading="lazy" alt={`Thumbnail-${i}`} />
                          </SwiperSlide>
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div className="detail-page-content">
                  <div className="top-details">
                    <h6 className="brand-name">{productDetails?.brand?.name}</h6>
                    <p className='pb-2'>{productDetails?.name}</p>
                    {productDetails?.tags && <p className='pb-0 product-tags'>{productDetails?.tags}</p>}
                    <ul className="rating-list">
                      <li className="rating-detail gap-2">
                        <span>
                          <h6 className="flex align-items-center">{productDetails?.rating}
                            <i className="fa-solid fa-star" style={{ lineHeight: '1.5', fontSize: 10, paddingLeft: 5 }} />
                          </h6>
                        </span>
                        <span>
                          <p className="py-0 px-2">{productDetails?.rating}  Rating</p>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="details-page-special-offer">
                    {/* <h5 className="font-16">Special Offer</h5> */}
                    <h4>{productDetails?.indiazona_price}
                      <span style={{ padding: '2px 10px', color: '#8a8c96' }}>MRP:<del className='mx-1'> ₹{productDetails?.tag_price}</del></span>
                      <span className="off-span">({productDetails?.automated_discount}% OFF)</span></h4>
                    <h4 className="daily-saver-text pb-0">inclusive of all taxes</h4>
                  </div>

                  <div>
                    <div className="row">
                      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-12">
                        <div className="row d-flex align-items-center">
                          <div className="col-6">
                            <div className="tf-product-info-quantity">
                              <div className="quantity-title fw-6">Quantity {productDetails?.attributes?.available_stock === 0 && <span className='small' style={{ color: "red" }}>Out of Stock</span>}</div>
                              <div className="wg-quantity">
                                {
                                  productDetails?.attributes?.available_stock > 0 ?
                                    <>
                                      <span className="btn-quantity minus-btn" onClick={handleDecrement}>-</span>
                                      <input
                                        type="number"
                                        name="quantity"
                                        min="1"
                                        value={formik.values.quantity}
                                        onChange={formik.handleChange}
                                        disabled
                                      />
                                      <span className="btn-quantity plus-btn" onClick={handleIncrement}
                                        disabled={formik.values.quantity >= (productDetails?.low_stock_quantity || Infinity)}
                                      >+</span>
                                    </> : <></>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row  pt-2">
                              <div className="col-6 col-lg-4 col-md-6">
                                <span style={{ cursor: 'pointer' }} onClick={handleShowCompare}>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={ReactToolTip('Add to Compare')}>
                                    <div className="compare&wishlist compare wishlist">
                                      <div className="compare-inner">
                                        <i className="fa-solid fa-arrow-right-arrow-left" />
                                      </div>
                                    </div>
                                  </OverlayTrigger>
                                </span>
                              </div>

                              <div className="col-6 col-lg-4 col-md-6" onClick={toggleWishlist}>
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={ReactToolTip('Add to Wishlist')}>
                                  <div className="compare&wishlist compare wishlist">
                                    <div className="wishlist-inner">
                                      <i className={`fa-${isInWishlist ? 'solid' : 'regular'} fa-heart ${isInWishlist ? 'text-danger' : 'text-black'}`} />
                                      {/* <i className={`fa-heart ${storedWishlist?.findIndex(item => item?.product?.id === productDetails?.id) === -1 ? 'fa-regular text-black' : 'fa-solid text-danger'}`} /> */}
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {productDetails?.attributes?.available_stock > 0 && productDetails?.attributes?.colors && (
                      <>
                        <div className="details-page-special-color marginbottom-30">
                          <h5 className="font-16">{productDetails?.attributes?.colors ? 'Color' : ''}</h5>
                          <ul className="flex align-items-center">
                            {productDetails?.attributes?.colors?.slice(0, 3).map((elem, i) => (
                              <li key={i} className={`li-circle ${selectedColor === elem.code ? 'active' : ''}`}>
                                <button
                                  style={{ backgroundColor: elem.code }}
                                  className='color-btn-show'
                                  onClick={() => handleColorClick(elem?.code)}
                                >
                                  {selectedColor === elem.code ? <Check /> : ''}
                                </button>
                              </li>
                            ))}
                            {!showAllColors && productDetails?.attributes?.colors?.length > 3 && (
                              <button className="btn more-btn" onClick={handleShowMore}>
                                +{productDetails.attributes?.colors?.length - 5} more
                              </button>
                            )}

                            {showAllColors && productDetails?.attributes?.colors?.slice(4).map((elem, i) => (
                              <li key={i} className={`li-circle ${selectedColor === elem.code ? 'active' : ''}`}>
                                <button
                                  style={{ backgroundColor: elem.code }}
                                  className='color-btn-show'
                                  onClick={() => handleColorClick(elem?.code)}
                                >
                                  {selectedColor === elem.code ? <Check /> : ''}
                                </button>
                              </li>
                            ))
                            }
                          </ul>
                          <p className="text-danger">
                            {formik.errors.selectedColor}
                          </p>
                        </div>

                        {selectedColor !== '' && productDetails?.attributes?.colors[0]?.sizes.length > 0 &&
                          <div className="details-page-special-color">
                            <h5 className="d-flex gap-5 font-16"> Find your size
                              <span className="size-chart" onClick={() => setShowSizeChart(!showSizeChart)} style={{ cursor: 'pointer' }}>
                                <i className="fa-solid fa-shirt px-1"></i>
                                Size Chart
                              </span>
                            </h5>

                            <div className="details-page-special-color ">
                              <ul className="d-flex">
                                {productDetails?.attributes?.colors[0]?.sizes?.map((elem, i) => (
                                  <li key={i} onClick={() => formik.setFieldValue('size', elem.size)} style={{ cursor: 'pointer' }}
                                    className={`li-circle ${formik.values.size === elem.size ? 'active' : ''}`}>
                                    <h6>{elem?.size}</h6>
                                  </li>
                                ))}
                              </ul>
                              <div className="error-message" style={{ color: 'red' }}>
                                {formik.errors.size}
                              </div>
                            </div>
                            <div className="size-chart-modal" style={{ display: showSizeChart ? 'block' : 'none' }}></div>
                          </div>
                        }
                      </>
                    )}


                    {/* <div className="details-page-special-color">
                      {productDetails?.attributes?.size ? (
                        <h5 className="d-flex gap-5 font-16"> Find your size
                          <span className="size-chart" onClick={() => setShowSizeChart(!showSizeChart)} style={{ cursor: 'pointer' }}>
                            <i className="fa-solid fa-shirt px-1"></i>
                            Size Chart
                          </span>
                        </h5>
                      ) : null}

                      <div className="details-page-special-color ">
                        <h5 className="font-16 mt-2"></h5>
                        <ul className="flex">
                          {productDetails?.attributes?.size?.map((elem, i) => (
                            <li key={i} className={`li-circle ${formik.values.size === elem ? 'active' : ''}`}>
                              <h6><span onClick={() => formik.setFieldValue('size', elem)}>{elem?.name}</span></h6>
                            </li>
                          ))}
                        </ul>
                        <div className="error-message" style={{ color: 'red' }}>
                          {formik.errors.size}
                        </div>
                      </div>
                    </div> */}

                    {/* {productDetails?.product_colors?.length > 0 && (
                      <div className="details-page-special-color marginbottom-30">
                        <h5 className="font-16">Color</h5>
                        <ul className="flex align-items-center">
                          {colorsToShow.map((elem, i) => (
                            <li key={i} className={`li-circle ${selectedColor === elem.code ? 'active' : ''}`}>
                              <button
                                style={{ backgroundColor: elem.code }}
                                className='color-btn-show'
                                onClick={() => handleColorClick(elem?.code)}
                              >
                                {selectedColor === elem.code ? <Check /> : ''}
                              </button>
                            </li>
                          ))}
                          {!showAllColors && productDetails?.product_colors?.length > 5 && (
                            <button className="btn more-btn" onClick={handleShowMore}>
                              +{productDetails.product_colors.length - 5} more
                            </button>
                          )}
                        </ul>
                        <div className="error-message" style={{ color: 'red' }}>
                          {formik.errors.selectedColor}
                        </div>
                      </div>
                    )} */}

                    {/* <p style={{ color: 'red', fontWeight: '600' }}>Low stock alert!</p> */}
                  </div>

                  <div className="shopButton-section">
                    <div className="row">
                      <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4 col-6 marginbottom-30">
                        <div className="addtocart">
                          <button className="btn common-btn w-100 d-flex gap-1 justify-content-center align-items-center" type='button' onClick={handleAddtoCart} disabled={cartAddLoading || productDetails?.attributes?.available_stock === 0}>
                            {cartAddLoading ? 'Processing...' : (
                              <>
                                <i className="fa-solid fa-cart-shopping" />
                                <span> Add To Cart</span>
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4 col-6">
                        <div className="buy-btn">
                          <button className="buy-btn-green btn common-btn w-100 d-flex gap-2 justify-content-center align-items-center" disabled={productDetails?.attributes?.available_stock === 0}>
                            <img src="/assets/images/svg-img/buynowIcon.svg" alt="buynowIcon" />
                            <span> Buy Now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="delivery-options">
                    <div className="row">
                      <h5 className="font-16">Delivery Options</h5>
                      <div className="col-lg-6 col-md-8 col-sm-12 col-xxl-4 col-xl-4">
                        <form>
                          <div className="pincode-delivery position-relative">
                            <input type="number" className="form-control position-relative" placeholder="Enter Pincode" />
                            <button className="btn blue__btn">Check</button>
                          </div>
                        </form>
                      </div>
                      <h6 className="f-14">Please enter PIN code to check delivery time &amp; Pay on Delivery Availability</h6>
                      <div className="delivery-products">
                        <h6>100% Original Products</h6>
                        <h6>Pay on delivery might be available</h6>
                        <h6>Easy 14 days returns and exchanges</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <BestOffer /> */}
                <SellerDetails productDetails={productDetails} />
              </div>
            </div>
          </div>

          <div className="row row-accordion ">
            <RatingGraphSection />
            <ProductAccordion productDetails={productDetails} />
          </div>
          {/* <RatingProductDetails /> */}
        </div>
      </div>

      <SizeChart showSizeChart={showSizeChart} handleHideSizeChart={handleHideSizeChart} />
      <CompareModel showCompare={showCompare} handleHideCompare={handleHideCompare} />
      <SideCartMenu showSideCart={showSideCart} handleCloseSideCart={handleCloseSideCart} />
    </section>
  )
}

export default ProductDetails;